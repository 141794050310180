<template>
  <form class="ion-padding" @submit.prevent="submitForm">
      <ion-list>
        <ion-item>
          <ion-label position="floating">Title</ion-label>
          <ion-input type="text" required v-model="enteredTitle"/>
        </ion-item>
        <ion-item>
          <ion-thumbnail slot="start">
            <img :src="takenImageUrl" alt="">
          </ion-thumbnail>
          <ion-button type="button" fill="clear" @click="takePhoto">
            <ion-icon slot="start" :icon="camera"></ion-icon>
            <ion-label>Take Photo</ion-label>
          </ion-button>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Description</ion-label>
          <ion-textarea rows="5" v-model="enteredDescription"></ion-textarea>
        </ion-item>        
      </ion-list>
      <ion-button type="submit" expand="block" fill="outline">Save</ion-button>
    </form>
</template>

<script>
import { IonList, IonItem, IonLabel, IonInput, IonTextarea, IonButton, IonThumbnail, IonIcon } from "@ionic/vue";
import { camera } from 'ionicons/icons';
//import { Plugin, CameraResultType, CameraSource } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

export default {
    name: 'create-memory-form',
    emits: ['save-memory'],
    components:{IonList, IonItem, IonLabel, IonInput, IonTextarea, IonButton, IonThumbnail, IonIcon },
    data(){
      return{
        enteredTitle:'',
        enteredDescription: '',
        takenImageUrl: null,
        camera
      }
    },
    methods:{
      async takePhoto(){
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: CameraSource.Camera,
          quality: 60
        });
        this.takenImageUrl = photo.webPath;
      },
      submitForm(){
        const memoryData = {
          title : this.enteredTitle,
          imageUrl : this.takenImageUrl,
          description : this.enteredDescription
        };
        this.$emit('save-memory', memoryData);
      }
    }

}
</script>
